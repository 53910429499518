export const LAST_N_YEARS = (n) => Array.from({ length: n }, (_, i) => new Date().getFullYear() - i);

export const SEXES = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

export const PRACTICE_LOCATIONS = [
  {
    label: 'Alberta',
    value: 'location::Alberta',
  },
  {
    label: 'British Columbia',
    value: 'location::British Columbia',
  },
  {
    label: 'Manitoba',
    value: 'location::Manitoba',
  },
  {
    label: 'New Brunswick',
    value: 'location::New Brunswick',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'location::Newfoundland and Labrador',
  },
  {
    label: 'Nova Scotia',
    value: 'location::Nova Scotia',
  },
  {
    label: 'Ontario',
    value: 'location::Ontario',
  },
  {
    label: 'Prince Edward Island',
    value: 'location::Prince Edward Island',
  },
  {
    label: 'Quebec',
    value: 'location::Quebec',
  },
  {
    label: 'Saskatchewan',
    value: 'location::Saskatchewan',
  },
  {
    label: 'Northwest Territories',
    value: 'location::Northwest Territories',
  },
  {
    label: 'Nunavut',
    value: 'location::Nunavut',
  },
  {
    label: 'Yukon',
    value: 'location::Yukon',
  },
];

export const MINOR_AILMENTS = [
  {
    label: 'Acne (mild)',
    value: 'Acne (mild)'
  },
  {
    label: 'Allergic rhinitis',
    value: 'Allergic rhinitis'
  },
  {
    label: 'Aphthous ulcers (canker sores)',
    value: 'Aphthous ulcers (canker sores)'
  },
  {
    label: 'Candidal stomatitis (oral thrush)',
    value: 'Candidal stomatitis (oral thrush)'
  },
  {
    label: 'Conjunctivitis (bacterial, allergic, and viral)',
    value: 'Conjunctivitis (bacterial, allergic, and viral)'
  },
  {
    label: 'Dermatitis (atopic, eczema, allergic, and contact)',
    value: 'Dermatitis (atopic, eczema, allergic, and contact)'
  },
  {
    label: 'Diaper dermatitis',
    value: 'Diaper dermatitis'
  },
  {
    label: 'Dysmenorrhea',
    value: 'Dysmenorrhea'
  },
  {
    label: 'Gastroesophageal reflux disease (GERD)',
    value: 'Gastroesophageal reflux disease (GERD)'
  },
  {
    label: 'Hemorrhoids',
    value: 'Hemorrhoids'
  },
  {
    label: 'Herpes labialis (cold sores)',
    value: 'Herpes labialis (cold sores)'
  },
  {
    label: 'Impetigo',
    value: 'Impetigo'
  },
  {
    label: 'Insect bites and urticaria (hives)',
    value: 'Insect bites and urticaria (hives)'
  },
  {
    label: 'Musculoskeletal sprains and strains',
    value: 'Musculoskeletal sprains and strains'
  },
  {
    label: 'Nausea and vomiting of pregnancy',
    value: 'Nausea and vomiting of pregnancy'
  },
  {
    label: 'Pinworms and threadworms',
    value: 'Pinworms and threadworms'
  },
  {
    label: 'Tick bites (post-exposure prophylaxis to prevent Lyme disease)',
    value: 'Tick bites (post-exposure prophylaxis to prevent Lyme disease)'
  },
  {
    label: 'Uncomplicated urinary tract infections (UTIs)',
    value: 'Uncomplicated urinary tract infections (UTIs)'
  },
  {
    label: 'Vulvovaginal candidiasis (yeast infection)',
    value: 'Vulvovaginal candidiasis (yeast infection)'
  }
];

export const LAGUAGES_SPOKEN = [
  { label: 'English', value: 'lang::English' },
  { label: 'Abkhaz', value: 'lang::Abkhaz' },
  { label: 'Acehnese', value: 'lang::Acehnese' },
  { label: 'Acholi', value: 'lang::Acholi' },
  { label: 'Afar', value: 'lang::Afar' },
  { label: 'Afrikaans', value: 'lang::Afrikaans' },
  { label: 'Albanian', value: 'lang::Albanian' },
  { label: 'Alur', value: 'lang::Alur' },
  { label: 'Amharic', value: 'lang::Amharic' },
  { label: 'Arabic', value: 'lang::Arabic' },
  { label: 'Armenian', value: 'lang::Armenian' },
  { label: 'Assamese', value: 'lang::Assamese' },
  { label: 'Avar', value: 'lang::Avar' },
  { label: 'Awadhi', value: 'lang::Awadhi' },
  { label: 'Aymara', value: 'lang::Aymara' },
  { label: 'Azerbaijani', value: 'lang::Azerbaijani' },
  { label: 'Balinese', value: 'lang::Balinese' },
  { label: 'Baluchi', value: 'lang::Baluchi' },
  { label: 'Bambara', value: 'lang::Bambara' },
  { label: 'Baoulé', value: 'lang::Baoulé' },
  { label: 'Bashkir', value: 'lang::Bashkir' },
  { label: 'Basque', value: 'lang::Basque' },
  { label: 'Batak Karo', value: 'lang::Batak Karo' },
  { label: 'Batak Simalungun', value: 'lang::Batak Simalungun' },
  { label: 'Batak Toba', value: 'lang::Batak Toba' },
  { label: 'Belarusian', value: 'lang::Belarusian' },
  { label: 'Bemba', value: 'lang::Bemba' },
  { label: 'Bengali', value: 'lang::Bengali' },
  { label: 'Betawi', value: 'lang::Betawi' },
  { label: 'Bhojpuri', value: 'lang::Bhojpuri' },
  { label: 'Bikol', value: 'lang::Bikol' },
  { label: 'Bosnian', value: 'lang::Bosnian' },
  { label: 'Breton', value: 'lang::Breton' },
  { label: 'Bulgarian', value: 'lang::Bulgarian' },
  { label: 'Buryat', value: 'lang::Buryat' },
  { label: 'Cantonese', value: 'lang::Cantonese' },
  { label: 'Catalan', value: 'lang::Catalan' },
  { label: 'Cebuano', value: 'lang::Cebuano' },
  { label: 'Chamorro', value: 'lang::Chamorro' },
  { label: 'Chechen', value: 'lang::Chechen' },
  { label: 'Chichewa', value: 'lang::Chichewa' },
  { label: 'Chinese (Simplified)', value: 'lang::Chinese (Simplified)' },
  { label: 'Chinese (Traditional)', value: 'lang::Chinese (Traditional)' },
  { label: 'Chuukese', value: 'lang::Chuukese' },
  { label: 'Chuvash', value: 'lang::Chuvash' },
  { label: 'Corsican', value: 'lang::Corsican' },
  { label: 'Crimean Tatar', value: 'lang::Crimean Tatar' },
  { label: 'Croatian', value: 'lang::Croatian' },
  { label: 'Czech', value: 'lang::Czech' },
  { label: 'Danish', value: 'lang::Danish' },
  { label: 'Dari', value: 'lang::Dari' },
  { label: 'Dhivehi', value: 'lang::Dhivehi' },
  { label: 'Dinka', value: 'lang::Dinka' },
  { label: 'Dogri', value: 'lang::Dogri' },
  { label: 'Dombe', value: 'lang::Dombe' },
  { label: 'Dutch', value: 'lang::Dutch' },
  { label: 'Dyula', value: 'lang::Dyula' },
  { label: 'Dzongkha', value: 'lang::Dzongkha' },
  { label: 'Esperanto', value: 'lang::Esperanto' },
  { label: 'Estonian', value: 'lang::Estonian' },
  { label: 'Ewe', value: 'lang::Ewe' },
  { label: 'Faroese', value: 'lang::Faroese' },
  { label: 'Fijian', value: 'lang::Fijian' },
  { label: 'Filipino', value: 'lang::Filipino' },
  { label: 'Finnish', value: 'lang::Finnish' },
  { label: 'Fon', value: 'lang::Fon' },
  { label: 'French', value: 'lang::French' },
  { label: 'Frisian', value: 'lang::Frisian' },
  { label: 'Friulian', value: 'lang::Friulian' },
  { label: 'Fulani', value: 'lang::Fulani' },
  { label: 'Ga', value: 'lang::Ga' },
  { label: 'Galician', value: 'lang::Galician' },
  { label: 'Georgian', value: 'lang::Georgian' },
  { label: 'German', value: 'lang::German' },
  { label: 'Greek', value: 'lang::Greek' },
  { label: 'Guarani', value: 'lang::Guarani' },
  { label: 'Gujarati', value: 'lang::Gujarati' },
  { label: 'Haitian Creole', value: 'lang::Haitian Creole' },
  { label: 'Hakha Chin', value: 'lang::Hakha Chin' },
  { label: 'Hausa', value: 'lang::Hausa' },
  { label: 'Hawaiian', value: 'lang::Hawaiian' },
  { label: 'Hebrew', value: 'lang::Hebrew' },
  { label: 'Hiligaynon', value: 'lang::Hiligaynon' },
  { label: 'Hindi', value: 'lang::Hindi' },
  { label: 'Hmong', value: 'lang::Hmong' },
  { label: 'Hungarian', value: 'lang::Hungarian' },
  { label: 'Hunsrik', value: 'lang::Hunsrik' },
  { label: 'Iban', value: 'lang::Iban' },
  { label: 'Icelandic', value: 'lang::Icelandic' },
  { label: 'Igbo', value: 'lang::Igbo' },
  { label: 'Ilocano', value: 'lang::Ilocano' },
  { label: 'Indonesian', value: 'lang::Indonesian' },
  { label: 'Irish', value: 'lang::Irish' },
  { label: 'Italian', value: 'lang::Italian' },
  { label: 'Jamaican Patois', value: 'lang::Jamaican Patois' },
  { label: 'Japanese', value: 'lang::Japanese' },
  { label: 'Javanese', value: 'lang::Javanese' },
  { label: 'Jingpo', value: 'lang::Jingpo' },
  { label: 'Kalaallisut', value: 'lang::Kalaallisut' },
  { label: 'Kannada', value: 'lang::Kannada' },
  { label: 'Kanuri', value: 'lang::Kanuri' },
  { label: 'Kapampangan', value: 'lang::Kapampangan' },
  { label: 'Kazakh', value: 'lang::Kazakh' },
  { label: 'Khasi', value: 'lang::Khasi' },
  { label: 'Khmer', value: 'lang::Khmer' },
  { label: 'Kiga', value: 'lang::Kiga' },
  { label: 'Kikongo', value: 'lang::Kikongo' },
  { label: 'Kinyarwanda', value: 'lang::Kinyarwanda' },
  { label: 'Kituba', value: 'lang::Kituba' },
  { label: 'Kokborok', value: 'lang::Kokborok' },
  { label: 'Komi', value: 'lang::Komi' },
  { label: 'Konkani', value: 'lang::Konkani' },
  { label: 'Korean', value: 'lang::Korean' },
  { label: 'Krio', value: 'lang::Krio' },
  { label: 'Kurdish (Kurmanji)', value: 'lang::Kurdish (Kurmanji)' },
  { label: 'Kurdish (Sorani)', value: 'lang::Kurdish (Sorani)' },
  { label: 'Kyrgyz', value: 'lang::Kyrgyz' },
  { label: 'Lao', value: 'lang::Lao' },
  { label: 'Latgalian', value: 'lang::Latgalian' },
  { label: 'Latin', value: 'lang::Latin' },
  { label: 'Latvian', value: 'lang::Latvian' },
  { label: 'Ligurian', value: 'lang::Ligurian' },
  { label: 'Limburgish', value: 'lang::Limburgish' },
  { label: 'Lingala', value: 'lang::Lingala' },
  { label: 'Lithuanian', value: 'lang::Lithuanian' },
  { label: 'Lombard', value: 'lang::Lombard' },
  { label: 'Luganda', value: 'lang::Luganda' },
  { label: 'Luo', value: 'lang::Luo' },
  { label: 'Luxembourgish', value: 'lang::Luxembourgish' },
  { label: 'Macedonian', value: 'lang::Macedonian' },
  { label: 'Madurese', value: 'lang::Madurese' },
  { label: 'Maithili', value: 'lang::Maithili' },
  { label: 'Makassar', value: 'lang::Makassar' },
  { label: 'Malagasy', value: 'lang::Malagasy' },
  { label: 'Malay', value: 'lang::Malay' },
  { label: 'Malay (Jawi)', value: 'lang::Malay (Jawi)' },
  { label: 'Malayalam', value: 'lang::Malayalam' },
  { label: 'Maltese', value: 'lang::Maltese' },
  { label: 'Mam', value: 'lang::Mam' },
  { label: 'Manx', value: 'lang::Manx' },
  { label: 'Maori', value: 'lang::Maori' },
  { label: 'Marathi', value: 'lang::Marathi' },
  { label: 'Marshallese', value: 'lang::Marshallese' },
  { label: 'Marwadi', value: 'lang::Marwadi' },
  { label: 'Mauritian Creole', value: 'lang::Mauritian Creole' },
  { label: 'Meadow Mari', value: 'lang::Meadow Mari' },
  { label: 'Meiteilon (Manipuri)', value: 'lang::Meiteilon (Manipuri)' },
  { label: 'Minang', value: 'lang::Minang' },
  { label: 'Mizo', value: 'lang::Mizo' },
  { label: 'Mongolian', value: 'lang::Mongolian' },
  { label: 'Myanmar (Burmese)', value: 'lang::Myanmar (Burmese)' },
  { label: 'Nahuatl (Eastern Huasteca)', value: 'lang::Nahuatl (Eastern Huasteca)' },
  { label: 'Ndau', value: 'lang::Ndau' },
  { label: 'Ndebele (South)', value: 'lang::Ndebele (South)' },
  { label: 'Nepalbhasa (Newari)', value: 'lang::Nepalbhasa (Newari)' },
  { label: 'Nepali', value: 'lang::Nepali' },
  { label: 'NKo', value: 'lang::NKo' },
  { label: 'Norwegian', value: 'lang::Norwegian' },
  { label: 'Nuer', value: 'lang::Nuer' },
  { label: 'Occitan', value: 'lang::Occitan' },
  { label: 'Odia (Oriya)', value: 'lang::Odia (Oriya)' },
  { label: 'Oromo', value: 'lang::Oromo' },
  { label: 'Ossetian', value: 'lang::Ossetian' },
  { label: 'Pangasinan', value: 'lang::Pangasinan' },
  { label: 'Papiamento', value: 'lang::Papiamento' },
  { label: 'Pashto', value: 'lang::Pashto' },
  { label: 'Persian', value: 'lang::Persian' },
  { label: 'Polish', value: 'lang::Polish' },
  { label: 'Portuguese (Brazil)', value: 'lang::Portuguese (Brazil)' },
  { label: 'Portuguese (Portugal)', value: 'lang::Portuguese (Portugal)' },
  { label: 'Punjabi (Gurmukhi)', value: 'lang::Punjabi (Gurmukhi)' },
  { label: 'Punjabi (Shahmukhi)', value: 'lang::Punjabi (Shahmukhi)' },
  { label: 'Quechua', value: 'lang::Quechua' },
  { label: 'Qʼeqchiʼ', value: 'lang::Qʼeqchiʼ' },
  { label: 'Romani', value: 'lang::Romani' },
  { label: 'Romanian', value: 'lang::Romanian' },
  { label: 'Rundi', value: 'lang::Rundi' },
  { label: 'Russian', value: 'lang::Russian' },
  { label: 'Sami (North)', value: 'lang::Sami (North)' },
  { label: 'Samoan', value: 'lang::Samoan' },
  { label: 'Sango', value: 'lang::Sango' },
  { label: 'Sanskrit', value: 'lang::Sanskrit' },
  { label: 'Santali', value: 'lang::Santali' },
  { label: 'Scots Gaelic', value: 'lang::Scots Gaelic' },
  { label: 'Sepedi', value: 'lang::Sepedi' },
  { label: 'Serbian', value: 'lang::Serbian' },
  { label: 'Sesotho', value: 'lang::Sesotho' },
  { label: 'Seychellois Creole', value: 'lang::Seychellois Creole' },
  { label: 'Shan', value: 'lang::Shan' },
  { label: 'Shona', value: 'lang::Shona' },
  { label: 'Sicilian', value: 'lang::Sicilian' },
  { label: 'Silesian', value: 'lang::Silesian' },
  { label: 'Sindhi', value: 'lang::Sindhi' },
  { label: 'Sinhala', value: 'lang::Sinhala' },
  { label: 'Slovak', value: 'lang::Slovak' },
  { label: 'Slovenian', value: 'lang::Slovenian' },
  { label: 'Somali', value: 'lang::Somali' },
  { label: 'Spanish', value: 'lang::Spanish' },
  { label: 'Sundanese', value: 'lang::Sundanese' },
  { label: 'Susu', value: 'lang::Susu' },
  { label: 'Swahili', value: 'lang::Swahili' },
  { label: 'Swati', value: 'lang::Swati' },
  { label: 'Swedish', value: 'lang::Swedish' },
  { label: 'Tahitian', value: 'lang::Tahitian' },
  { label: 'Tajik', value: 'lang::Tajik' },
  { label: 'Tamazight', value: 'lang::Tamazight' },
  { label: 'Tamazight (Tifinagh)', value: 'lang::Tamazight (Tifinagh)' },
  { label: 'Tamil', value: 'lang::Tamil' },
  { label: 'Tatar', value: 'lang::Tatar' },
  { label: 'Telugu', value: 'lang::Telugu' },
  { label: 'Tetum', value: 'lang::Tetum' },
  { label: 'Thai', value: 'lang::Thai' },
  { label: 'Tibetan', value: 'lang::Tibetan' },
  { label: 'Tigrinya', value: 'lang::Tigrinya' },
  { label: 'Tiv', value: 'lang::Tiv' },
  { label: 'Tok Pisin', value: 'lang::Tok Pisin' },
  { label: 'Tongan', value: 'lang::Tongan' },
  { label: 'Tsonga', value: 'lang::Tsonga' },
  { label: 'Tswana', value: 'lang::Tswana' },
  { label: 'Tulu', value: 'lang::Tulu' },
  { label: 'Tumbuka', value: 'lang::Tumbuka' },
  { label: 'Turkish', value: 'lang::Turkish' },
  { label: 'Turkmen', value: 'lang::Turkmen' },
  { label: 'Tuvan', value: 'lang::Tuvan' },
  { label: 'Twi', value: 'lang::Twi' },
  { label: 'Udmurt', value: 'lang::Udmurt' },
  { label: 'Ukrainian', value: 'lang::Ukrainian' },
  { label: 'Urdu', value: 'lang::Urdu' },
  { label: 'Uyghur', value: 'lang::Uyghur' },
  { label: 'Uzbek', value: 'lang::Uzbek' },
  { label: 'Venda', value: 'lang::Venda' },
  { label: 'Venetian', value: 'lang::Venetian' },
  { label: 'Vietnamese', value: 'lang::Vietnamese' },
  { label: 'Waray', value: 'lang::Waray' },
  { label: 'Welsh', value: 'lang::Welsh' },
  { label: 'Wolof', value: 'lang::Wolof' },
  { label: 'Xhosa', value: 'lang::Xhosa' },
  { label: 'Yakut', value: 'lang::Yakut' },
  { label: 'Yiddish', value: 'lang::Yiddish' },
  { label: 'Yoruba', value: 'lang::Yoruba' },
  { label: 'Yucatec Maya', value: 'lang::Yucatec Maya' },
  { label: 'Zapotec', value: 'lang::Zapotec' },
  { label: 'Zulu', value: 'lang::Zulu' }
];